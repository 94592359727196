.navbar-link-text {
    color: red !important;
    text-decoration: none !important;
}

a {
    text-decoration: none !important;
}

.icon-white {
    color: white;
    font-size: 32px;
}