.modal-table {
    border: none;

    th, tr, td {
        padding: 0;
        margin-top: 1em;
        border: none;
    }

    th, td {
        border-bottom: 1px solid black;
    }

    td {
        text-align: left;
        padding: 0.5em 0;
    }
}



