.main-container{
    width: 95%;
    margin: auto;
}
.number-box{
    width: 100px;
    height: 60px;
    margin: auto;
    background-color: white;
    border-style: solid;
}

.report-color-box {
    cursor: pointer;

    &.active {
        border: 5px solid #fff;
        box-shadow: 0 0 3px 3px #0b0600;
    }
}
