.quill-dark-border {
    .ql-toolbar {
        border: 1px solid black !important;
    }

    .ql-container {
        border: 1px solid black !important;

        &.increase-height {
            height: 120px !important;
        }
    }
}

.increase-height {
    div[class^='ql-container'] {
        div{
            min-height: 200px;
        }
    }
}
