.work-performed-print p {
  padding: 2px !important;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 0 !important;
}

.semi-dark-border {
  border: 0px solid #1a1e21;
}

.mt-sm {
  margin-top: 0.5px;
}

div.page-footer {
  text-align: center;
  height: 50px;
  font-size: 10px;
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  width: 100%;
  border: none;
}

div.page-footer p {
  margin: 0;
  border: none !important;
}

.watermark {
  display: none;
  top: 50vh;
  z-index: -9;
  width: 50vw;
  page-break-after: always;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  border: none !important;
}

table.report-container {
  page-break-after: always;
  width: 100%;
  height: 100%;
  border: none !important;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
  border: none;
}

div.footer-info, div.page-footer {
  display: none;
  margin-top: 0px;
  border: none;
}

@media print {
  @page {
    size: A4;
    border: none;
  }
  div {
    break-inside: avoid;
  }
  .watermark {
    display: block;
    counter-increment: page;
    content: counter();
    position: fixed;
    border: none;
  }
  div.page-footer, div.footer-info {
    display: block;
    border: none;
  }
}

