*::placeholder {
    font-size: 14px;
    color: darkgray !important;
}

body {
    width: 100% !important;
}

/* widths */
.w-0 { width: 0% !important; }
.w-10 { width: 10% !important; }
.w-15 { width: 15% !important; }
.w-20 { width: 20% !important; }
.w-30 { width: 30% !important; }
.w-35 { width: 35% !important; }
.w-40 { width: 40% !important; }
.w-45 { width: 45% !important; }
.w-55 { width: 55% !important; }
.w-60 { width: 60% !important; }
.w-65 { width: 65% !important; }
.w-70 { width: 70% !important; }
.w-80 { width: 80% !important; }
.w-85 { width: 85% !important; }
.w-90 { width: 90% !important; }
.w-95 { width: 95% !important; }
.w-100 { width: 100% !important; }
.w-auto { width: auto !important; }

/* min widths */
.mw-0 { min-width: 0 !important; }
.mw-10 { min-width: 10% !important; }
.mw-15 { min-width: 15% !important; }
.mw-20 { min-width: 20% !important; }
.mw-25 { min-width: 25% !important; }
.mw-30 { min-width: 30% !important; }
.mw-35 { min-width: 35% !important; }
.mw-40 { min-width: 40% !important; }
.mw-45 { min-width: 45% !important; }
.mw-50 { min-width: 50% !important; }
.mw-55 { min-width: 55% !important; }
.mw-60 { min-width: 60% !important; }
.mw-65 { min-width: 65% !important; }
.mw-70 { min-width: 70% !important; }
.mw-75 { min-width: 75% !important; }
.mw-80 { min-width: 80% !important; }
.mw-85 { min-width: 85% !important; }
.mw-90 { min-width: 90% !important; }
.mw-95 { min-width: 95% !important; }
.mw-100 { min-width: 100% !important; }
.mw-auto { min-width: auto !important; }

/* heights */
.h-0 { height: 0 !important; }
.h-10 { height: 10% !important; }
.h-15 { height: 15% !important; }
.h-20 { height: 20% !important; }
.h-25 { height: 25% !important; }
.h-30 { height: 30% !important; }
.h-35 { height: 35% !important; }
.h-40 { height: 40% !important; }
.h-45 { height: 45% !important; }
.h-50 { height: 50% !important; }
.h-55 { height: 55% !important; }
.h-60 { height: 60% !important; }
.h-65 { height: 65% !important; }
.h-70 { height: 70% !important; }
.h-75 { height: 75% !important; }
.h-80 { height: 80% !important; }
.h-85 { height: 85% !important; }
.h-90 { height: 90% !important; }
.h-95 { height: 95% !important; }
.h-100 { height: 100% !important; }
.h-auto { height: auto !important; }

/* min heights */
.mh-0 { min-height: 0 !important; }
.mh-10 { min-height: 10% !important; }
.mh-20 { min-height: 20% !important; }
.mh-30 { min-height: 30% !important; }
.mh-40 { min-height: 40% !important; }
.mh-50 { min-height: 50% !important; }
.mh-60 { min-height: 60% !important; }
.mh-70 { min-height: 70% !important; }
.mh-80 { min-height: 80% !important; }
.mh-90 { min-height: 90% !important; }
.mh-100 { min-height: 100% !important; }
.mh-auto { min-height: auto !important; }

/* viewport heights */
.vh-10 { height: 10vh !important; };
.vh-20 { height: 20vh !important; };
.vh-30 { height: 30vh !important; };
.vh-40 { height: 40vh !important; };
.vh-50 { height: 50vh !important; };
.vh-60 { height: 60vh !important; };
.vh-70 { height: 70vh !important; };
.vh-80 { height: 80vh !important; };
.vh-90 { height: 90vh !important; };
.vh-100 { height: 100vh !important; };

/* font sizes */
.fs-10 { font-size: 10px !important; }
.fs-12 { font-size: 12px !important; }
.fs-14 { font-size: 14px !important; }
.fs-16 { font-size: 16px !important; }
.fs-18 { font-size: 18px !important; }
.fs-20 { font-size: 20px !important; }
.fs-22 { font-size: 22px !important; }
.fs-24 { font-size: 24px !important; }
.fs-26 { font-size: 26px !important; }
.fs-28 { font-size: 28px !important; }
.fs-30 { font-size: 30px !important; }
.fs-32 { font-size: 32px !important; }
.fs-34 { font-size: 34px !important; }
.fs-36 { font-size: 36px !important; }
.fs-38 { font-size: 38px !important; }
.fs-40 { font-size: 40px !important; }
.fs-42 { font-size: 42px !important; }
.fs-44 { font-size: 44px !important; }
.fs-46 { font-size: 46px !important; }
.fs-48 { font-size: 48px !important; }
.fs-50 { font-size: 50px !important; }
.fs-52 { font-size: 52px !important; }
.fs-54 { font-size: 54px !important; }
.fs-64 { font-size: 64px !important; }
.fs-72 { font-size: 72px !important; }
.fs-86 { font-size: 86px !important; }
.fs-98 { font-size: 98px !important; }

.top-5 { top: 5px !important; }
.top-10 { top: 10px !important; }
.top-15 { top: 15px !important; }
.top-20 { top: 20px !important; }

/* dropdown */
.dropdown {
    position: relative;
    display: inline-block;

    &:hover {
        .dropdown-content {
            display: block;
            border-radius: 3px;
        }
    }

    .dropdown-btn {

    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 160px;
        z-index: 1;

        a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            font-size: 16px;
            &:hover {
                background-color: #0d6efd;
                color: white;
            }
        }
    }
}

/* pointers */
.pointer {
    cursor: pointer;
}

.back-hover {
    z-index: -2 !important;
    &:hover {
        opacity: 1 !important;
    }
}