.loader {
    border: 5px solid lightgray;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin:auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    position:fixed;
}

.low-opacity-form {
    opacity: 0.4;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

textarea {
    resize: none;
}

.service-report-tables {
    border-collapse: collapse;
    border-spacing: 0;

    th, tr, td {
        border: 1px solid black;
    }
    th {
        min-height: 50px;
        max-height: 50px;
    }
}

.print-tech-table {
    border-right: none !important;
    th, tr, td {
        border: 1px solid black;
        border-bottom: none !important;
        overflow: hidden;
        width: 100px;
    }
    tr, td {
    }
}

.print-service-report-table {
    th, tr, td {
        border: 1px solid black;
        border-bottom: none !important;
        overflow: hidden;
    }
    tr, td {
        padding: 0.2rem;
    }
}

.customer-autocomplete {
    width: 29.1%;
    max-height: 40%;
    overflow: auto;
    position: absolute;
    background-color: white;
    border: 2px solid gainsboro;

    .customer-options {
        border: 1px solid #ced4da;
        padding: 5px;

        &:hover {
            background-color: paleturquoise;
        }
    }
}
