.user-table {
    thead {
        tr {

        }
    }
    tbody {
        tr {
            border: 0;
            font-weight: normal;
        }

        th {
            border: 0;
            font-weight: normal;
        }

        td {
            border: 0;

        }

        .invert-white {
            td {
                color: white;
            }
        }
    }
}
