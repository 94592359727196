.modal-entrance {
    animation: modal-entrance 0.8s ease 0s 1 normal none;
    @keyframes modal-entrance {
        0% {
            opacity: 0;
            transform: translateY(-250px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}